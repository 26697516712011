import Glide from "@glidejs/glide";

const slides = document.querySelectorAll(".glide");

Object.values(slides).map((slideEl) => {
  const slider = new Glide(slideEl, {
    perView: 1,
    type: "slider",
    focusAt: "center",
    autoplay: 4000,
    loop: true,
  });
  slider.mount();
});
